
import { defineComponent, ref, Ref, inject, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { UploadOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import { AxiosStatic } from "axios";
import { FileItem, UploadNotification, VisitPatient } from "../../types";
import { DefaultOptionType } from "ant-design-vue/lib/vc-select/Select";

interface VisitPatientLocal {
  patientId: number;
  patientName: string;
}

export const AddDocumentsComponent = defineComponent({
  components: {
    UploadOutlined,
  },
  emits: ["after-confirm-success", "update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    visitId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const confirmLoading = ref(false);
    const visible = ref(props.modelValue);
    const fileList: Ref<Array<FileItem>> = ref([]);
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const patientList: Ref<VisitPatient> = ref({});
    const selectedPatient: Ref<VisitPatientLocal> = ref({
      patientId: 0,
      patientName: "",
    });
    const uploadDisabled: Ref<boolean> = ref(false);

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
      }
    );

    const handleSelectChange = (
      value: VisitPatientLocal["patientName"],
      obj: DefaultOptionType
    ) => {
      selectedPatient.value = {
        patientId: (obj.key ?? 0) as number,
        patientName: (obj.value ?? "") as VisitPatientLocal["patientName"],
      };
    };

    const handleRemoveFile = (file: FileItem) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };
    const beforeUploadFile = (file: FileItem) => {
      fileList.value = [...fileList.value, file];
      return false;
    };
    const handleCancel = () => {
      visible.value = false;
    };
    const afterClose = () => {
      fileList.value = [];
      emit("update:modelValue", false);
    };

    const getPatients = async () => {
      try {
        patientList.value = (await http
          .get(`/patient-portal/api/appointments/${props.visitId}/patients`)
          .then((res) => res.data)) as VisitPatient;
      } catch {
        notification.open({
          message: t("ERROR.4982"),
          class: "error",
        });
        uploadDisabled.value = true;
      } finally {
        selectedPatient.value = {
          patientId: parseInt(Object.keys(patientList.value)[0]),
          patientName: Object.values(patientList.value)[0],
        };
      }
    };

    const addDocuments = async () => {
      confirmLoading.value = true;
      const promises: Array<Promise<string>> = [];
      const notifications: Array<UploadNotification> = [];

      fileList.value.forEach((file: FileItem) => {
        const formData = new FormData();
        formData.append("file", file.originFileObj);
        promises.push(
          http.post(
            `patient-portal/api/documentation/${props.visitId}/${selectedPatient.value.patientId}`,
            formData
          )
        );
      });
      await Promise.allSettled(
        promises.map((p: Promise<string>, index: number) => {
          p.then(() => {
            notifications.push({
              class: "success",
              file: fileList.value[index],
              message: t("ADD_DOCUMENTS.SUCCESS", {
                filename: fileList.value[index].name,
              }),
            });
          }).catch((e) => {
            notifications.push({
              class: "error",
              file: fileList.value[index],
              message: t(`ERROR.${e.response.status}3`),
            });
          });
          return p;
        })
      );

      confirmLoading.value = false;
      return new Promise((resolve) => {
        notifications.forEach((notify, index) => {
          if (notify.class === "success") {
            handleRemoveFile(notify.file);
          }
          setTimeout(() => {
            notification.open(notify);
          }, index * 100);
        });
        if (fileList.value.length !== promises.length) {
          emit("after-confirm-success");
        }

        if (fileList.value.length === 0) {
          visible.value = false;
        }
        resolve(notifications);
      });
    };

    onMounted(() => {
      getPatients();
    });

    return {
      beforeUploadFile,
      handleRemoveFile,
      handleCancel,
      afterClose,
      addDocuments,
      confirmLoading,
      fileList,
      visible,
      t,
      patientList,
      handleSelectChange,
      selectedPatient,
      uploadDisabled,
    };
  },
});

export default AddDocumentsComponent;
