import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_UploadOutlined = _resolveComponent("UploadOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "Dodaj dokument",
    class: "add-documents",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    "after-close": _ctx.afterClose
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        key: "submit",
        type: "primary",
        class: "add-documents__submit",
        loading: _ctx.confirmLoading,
        disabled: _ctx.fileList.length === 0,
        onClick: _ctx.addDocuments
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("ADD_DOCUMENTS.ADD_FILES")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading", "disabled", "onClick"]),
      _createVNode(_component_a_button, {
        class: "add-documents__cancel",
        onClick: _ctx.handleCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("ADD_DOCUMENTS.CANCEL")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.t("ADD_DOCUMENTS.HEADER")), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString(_ctx.t("ADD_DOCUMENTS.FORMATS")), 1 /* TEXT */),
      _createVNode(_component_a_select, {
        value: _ctx.selectedPatient.patientName,
        onSelect: _ctx.handleSelectChange,
        class: "add-documents__patient-select"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patientList, (value, key) => {
            return (_openBlock(), _createBlock(_component_a_select_option, {
              value: value,
              key: key
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(value), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value", "onSelect"]),
      _createVNode(_component_a_upload, {
        "show-upload-list": { showRemoveIcon: true, showPreviewIcon: false },
        onRemove: _ctx.handleRemoveFile,
        multiple: true,
        "before-upload": _ctx.beforeUploadFile,
        disabled: _ctx.confirmLoading || _ctx.uploadDisabled,
        class: "visit__upload",
        "file-list": _ctx.fileList,
        "onUpdate:file-list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileList) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, null, {
            default: _withCtx(() => [
              _createVNode(_component_UploadOutlined),
              _createTextVNode(" " + _toDisplayString(_ctx.t("ADD_DOCUMENTS.UPLOAD")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onRemove", "before-upload", "disabled", "file-list"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "after-close"]))
}